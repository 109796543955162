'use client';

import { Root } from '@radix-ui/react-popover';
import { FC, ReactNode } from 'react';

type Props = {
	children?: ReactNode;
};

const NavbarMenuWrapper: FC<Props> = ({ children }) => {
	return <Root>{children}</Root>;
};

export default NavbarMenuWrapper;
