'use client';
import Button from '@egalite/ui/Buttons';
import { FC, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import clx from 'classnames';
import Icon from '@egalite/ui/Icon';
import Link from 'next/link';
import Typography from '@egalite/ui/Typography';
import NavbarLink from '@egalite/ui/IncluiPCD/Navbar/Company/NavbarLink';

const CompanyButton: FC = () => {
	const [user, setUser] = useState(null);
	useEffect(() => {
		const token = Cookies.get('token');
		if (token && (user == null || Object.keys(user).length === 0)) {
			fetch('/api/v1/auth/fetchUser', {
				headers: {
					Authorization: `${token}`,
				},
				method: 'GET',
			})
				.then(res => res.json())
				.then(data => {
					setUser(data.data);
				});
			return;
		}
	}, [user]);
	return (
		<div className="group relative">
			<Button
				iconPosition="end"
				icon={<Icon className="fi fi-br-angle-down text-white" />}
				size="small"
				md="large"
				className="gap-4 px-6 md:px-10 peer border-white"
			>
				{user?.name?.first ?? user?.name?.full?.substring(0, (user?.name?.full ?? '').indexOf(" ")) ?? 'Candidato'}
			</Button>
			<div
				className={clx(
					'flex flex-col p-10 rounded-3xl shadow-lg absolute top-30 right-0 bg-white text-base-grey-5 z-50 transition-all transition-300 ease-in-out',
					'scale-y-0 opacity-0 origin-top',
					'group-hover:scale-y-100 group-hover:opacity-100 group-hover:translate-y-0',
					'peer-focus:scale-y-100 peer-focus:opacity-100 peer-focus:translate-y-0',
					'focus-within:scale-y-100 focus-within:opacity-100 focus-within:translate-y-0',
				)}
			>
				<NavbarLink href="/login"  className='md:hover:bg-base-grey-3 md:active:bg-base-grey-3'>Sair</NavbarLink>
			</div>
		</div>
	);
};
export default CompanyButton;
