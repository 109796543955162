/* eslint-disable react/prop-types */
import Link, { LinkProps } from 'next/link';
import React, { ButtonHTMLAttributes, HTMLAttributes, ReactElement, useMemo } from 'react';
import cn from '../Utils/cn';

type Variants = 'primary' | 'secondary' | 'tertiary' | 'general' | 'mono';
type Sizes = 'gigantic' | 'large' | 'medium' | 'small';
type IconPosition = 'start' | 'end';

type PropsBase = {
	disabled?: boolean;
	variant?: Variants;
	size?: Sizes;
	icon?: ReactElement<HTMLAttributes<HTMLElement>>;
	iconPosition?: IconPosition;
	children?: React.ReactNode;
	sm?: Sizes;
	md?: Sizes;
	lg?: Sizes;
	xl?: Sizes;
};

type PropsButton = {
	type?: 'button' | 'submit' | 'reset';
} & PropsBase &
	Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'children'>;

type PropsLink = {
	type?: 'link';
} & PropsBase &
	Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
	LinkProps & {
		children?: React.ReactNode;
	} & React.RefAttributes<HTMLAnchorElement>;

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, PropsButton | PropsLink>(
	({ children, icon, disabled, type = 'button', iconPosition = 'start', className, variant = 'primary', size = 'medium', sm, md, lg, xl, ...props }, ref) => {
		const classes = useMemo(
			() =>
				cn(
					'btn-base',
					{
						'btn-primary': variant === 'primary',
						'btn-secondary': variant === 'secondary',
						'btn-tertiary': variant === 'tertiary',
						'btn-general': variant === 'general',
						'btn-mono': variant === 'mono',
						'btn-small': size === 'small',
						'btn-medium': size === 'medium',
						'btn-large': size === 'large',
						'btn-gigantic': size === 'gigantic',
						[`sm:btn-${sm}`]: sm !== null && sm !== undefined,
						[`md:btn-${md}`]: md !== null && md !== undefined,
						[`lg:btn-${lg}`]: lg !== null && lg !== undefined,
						[`xl:btn-${xl}`]: xl !== null && xl !== undefined,
					},
					className,
				),
			[variant, size, sm, md, lg, xl, className],
		);

		if (type === 'link')
			return (
				<Link className={classes} {...(props as any)} ref={ref}>
					{iconPosition === 'start' && icon}
					{children}
					{iconPosition === 'end' && icon}
				</Link>
			);

		return (
			<button className={classes} type={type} {...(props as any)} disabled={disabled} ref={ref}>
				{iconPosition === 'start' && icon}
				{children}
				{iconPosition === 'end' && icon}
			</button>
		);
	},
);
Button.displayName = 'Button';

export default Button;
