'use client';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Loader from '../Loading/Loader';
import Typography from '../Typography';
import clx from 'classnames';

const Loading = ({ line = false, fullScreen = false, className = '', ...props }) => {
    return (
        <div
            className={clx('font-roboto text-[2rem] flex w-full justify-center items-center', fullScreen ? 'fixed h-full' : 'absolute h-auto', line ? 'flex-row-reverse gap-12' : 'flex-col', className)}
        >
            <Loader />
            <div
                {...props}
                css={{
                    animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
                    animation: 'textAnim 2s infinite',
                    '@keyframes textAnim': {
                        '0%': {
                            opacity: 1,
                        },
                        '50%': {
                            opacity: 0,
                        },
                        '100%': {
                            opacity: 1,
                        },
                    },
                }}
            ></div>
        </div>
    );
};

export default Loading;
