import Link from 'next/link';
import { FC } from 'react';

const NavbarLogo: FC = () => {
	return (
		<Link href="/" className="text-3xl lg:text-6xl leading-none font-medium tracking-tight">
			inclui<span className="text-brand-main font-extrabold">PcD</span>
		</Link>
	);
};

export default NavbarLogo;
