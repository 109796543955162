'use client';

import { FC } from 'react';
import { Anchor } from '@radix-ui/react-popover';

const NavbarMenuAnchor: FC = () => {
	return <Anchor className="fixed top-0 right-0"></Anchor>;
};

export default NavbarMenuAnchor;
