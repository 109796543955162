'use client';
import { FC, ReactNode } from 'react';
import NavbarAppButton from './NavbarAppButton';
import * as Dialog from '@radix-ui/react-dialog';
import NavbarLink from './NavbarLink';

const AppMenu: FC<{children: ReactNode}> = ({children}) => {
	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>
				<NavbarAppButton label="Mais opções" icon="fi-br-menu-dots" />
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className='fixed z-20 h-screen w-screen bg-brand-dark opacity-30 top-0 left-0 right-0 bottom-0' />
				<Dialog.Content className='bg-white w-screen fixed bottom-[98px] p-8 rounded-4xl z-30 text-base-grey-5'>
					<Dialog.Description>
                        {children}
                    </Dialog.Description>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export default AppMenu;
