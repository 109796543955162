'use client';
/** @jsxImportSource @emotion/react */

import Button from '@egalite/ui/Buttons';
import { css, keyframes } from '@emotion/react';
import { Close, Content, Portal } from '@radix-ui/react-popover';
import { FC, ReactNode } from 'react';
import Icon from '../../Icon';
import NavbarLogo from './NavbarLogo';

const slideInFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOutToRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const styles = css`
	will-change: transform, opacity;

	&[data-state='open'] {
		animation: ${slideInFromRight} 400ms ease-out;
	}

	&[data-state='closed'] {
		animation: ${slideOutToRight} 400ms ease-in;
	}
`;

type Props = {
	children?: ReactNode;
};

const NavbarMenuContent: FC<Props> = ({ children }) => {
	return (
		<Portal>
			<Content align="end" className="w-max h-max flex md:hidden flex-col items-end gap-8 bg-white px-14 py-10 shadow-xl rounded-l-4xl z-30" css={styles} asChild>
				<ul>
					<div className="flex gap-20 justify-between w-full">
						<Close asChild>
							<button className="text-4xl text-[#C6C6C6] hover:text-brand-main transition duration-200">
								<Icon name="close" />
							</button>
						</Close>

						<NavbarLogo />
					</div>

					<hr className="border-[#C6C6C6] w-full my-8" />

					{children}

					<hr className="border-[#C6C6C6] w-full my-8" />

					<Button variant="secondary" size="large" type="link" href="/login" className="w-full" icon={<Icon name="login" />}>
						Login
					</Button>

					<Button size="large" type="link" href="/cadastroempresa" className="w-full" icon={<Icon name="portrait" />}>
						Cadastre-se empresa
					</Button>

					<Button size="large" type="link" href="/candidato/cadastrar" className="w-full" icon={<Icon name="portrait" />}>
						Cadastre-se candidato
					</Button>
				</ul>
			</Content>
		</Portal>
	);
};

export default NavbarMenuContent;
