import { Url } from 'next/dist/shared/lib/router/router';
import Link from 'next/link';
import { FC, MouseEventHandler, ReactNode } from 'react';
import cn from '../../../Utils/cn';

type Props = {
	children: ReactNode;
	href: Url;
	className?: string;
};

const NavbarLink: FC<Props> = ({ href = '/', children, className }) =>
		<Link
			href={href}
			className={cn(
				'flex items-center text-base leading-none whitespace-nowrap min-h-[48px] px-4 py-2 rounded-xl',
				'hover:bg-base-grey-3 active:bg-base-grey-3',
				'md:font-bold md:px-6 lg:px-8 md:hover:bg-brand-dark md:active:bg-brand-dark',
				'transition-colors ease-in-out duration-200',
				className
			)}
		>
			{children}
		</Link>

export default NavbarLink;
