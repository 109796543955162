import Link from 'next/link';
import Icon from '../../../Icon';
import { FC } from 'react';
import cn from '../../../Utils/cn';

export const NavbarAppButton: FC<{
	label: string;
	icon: string;
	href?: string;
	onClick?: () => void;
	active?: boolean;
}> = ({ label, icon, href, onClick, active }) => (
	href ? (
		<Link href={href ?? '/'} className={cn('flex flex-col h-full justify-center items-center basis-1/5 grow shrink relative')}>
			{active && <div className="absolute w-20 h-4 bg-brand-main rounded-full -top-2"></div>}
			<Icon className={`fi ${icon} text-brand-main mx-auto my-2 text-3xl`} />
			<span className="text-base-grey-5 text-center text-[12px] uppercase w-full h-10">{label}</span>
		</Link>
	) : (
		<button onClick={onClick} className={cn('flex flex-col h-full justify-center items-center basis-1/5 grow shrink relative')}>
			{active && <div className="absolute w-20 h-4 bg-brand-main rounded-full -top-2"></div>}
			<Icon className={`fi ${icon} text-brand-main mx-auto my-2 text-3xl`} />
			<span className="text-base-grey-5 text-center text-[12px] uppercase w-full h-10">{label}</span>
		</button>
	)
);

export default NavbarAppButton;
