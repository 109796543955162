'use client';

import { Trigger } from '@radix-ui/react-popover';
import { FC } from 'react';
import Icon from '../../Icon';

const NavbarMenuButton: FC = () => {
	return (
		<Trigger className="md:hidden" title="nav-menu">
			<Icon name="menu-burger" className="text-6xl leading-none" />
		</Trigger>
	);
};

export default NavbarMenuButton;
